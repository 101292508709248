@media (max-width: $grid-float-breakpoint - 1) {
	.carrusel-cabecera,
	body {
		margin-top: 50px;
	}
}

.navbar-default {
	.navbar-toggle {
		border-radius: 0;
	}
}

.navbar-brand {
	color: #fff;
	text-align: left;
	padding-top: 8px;
}

.navbar-brand.ocultar .logo-separador,
.navbar-brand.ocultar .logo-texto {
	display: none;
}

.logo-icono {
	display: inline-block;
	width: 18px;
	height: 40px;
	transition: all 0.5s linear;
	margin-right: 0.5em;
	background-image: url('../images/logo_PE.svg');
	background-size: contain;
}

.navbar-carrusel,
.navbar-imagen {
	.logo-icono {
		@media (min-width: $breakpoint-menu) {
			width: 26px;
			height: 57px;
		}
	}
	.logo-nombre {
		@media (min-width: $breakpoint-menu) {
			font-size: 1.2em;
		}
	}
	.logo-separador {
		width: 3em;
		border-top: $col-brand-2 4px solid;
		padding-bottom: 1em;
		// @extend .visible-md-block;
		@extend .visible-lg-block;
	}

	.logo-texto {
		color: #fff;
		font-family: $font-2;
		font-style: italic;
		font-size: 0.9em;
		p {line-height: 1.5;}
		// @extend .visible-md-block;
		@extend .visible-lg-block;
	}
}

.logo-nombre {
	display: inline-block;
	font-size: 0.8em;
	transition: font-size 0.5s linear;
	text-transform: uppercase;
	padding-bottom: 1em;
	color: #fff;
}

h1.nombre-1,
h1.nombre-2 {
	font-size: 1.4em;
	font-weight: 600;
	line-height: 0.3em;
	margin: 0;
}

h1.nombre-1 {
	display: block;
}

h1.nombre-2 {
	display: inline-block;
}

.nombre-3 {
	font-size: 0.8em;
	font-weight: 200;
	display: inline-block;
}

.navbar {
	font-family: $font-1;
	text-align: center;
	font-weight: bold;
	letter-spacing: 1px;
	color: #fff;
	transition: color 0.5s linear;
	border: none;
	width: 100%;
	margin-bottom: 0;
	position: fixed;
	padding: 0.6em 0;
	@media (min-width: $grid-float-breakpoint) {
		background-color: transparent;
	}
}

.navbar {
	font-size: 0.6em;
	@media (min-width: $breakpoint-menu) {
		font-size: 0.7em;
	}
}
.nav>li>a {
	padding: 8px;
	@media (min-width: $breakpoint-menu) {
		padding: 12px;
	}
}

.navbar.navbar-menu-solo {
	background-color: $col-fondo-2;
}

.menu-solo {
	margin-top: 4em;
}

.navbar-default.scrolling {
	background-color: $col-fondo-2;
	transition: background-color 0.3s linear;
	& .logo-separador, & .logo-texto {
		visibility: hidden;
		display: none;
	}
	& .logo-icono {
		width: 18px;
		height: 40px;
	}
	& .logo-nombre {
		font-size: 0.8em;
	}
}

.navbar-default.scrolling .navbar-nav>li>a {
	color: #fff;
	transition: color 0.3s linear;
}

.navbar-nav {
	@media (min-width: $grid-float-breakpoint) {
		float: right;
		padding-right: 1em;
	}
}

.nav>li {
	display: inline-block;
	border: solid 1px $col-brand-2;
	margin-bottom: 4px;
	@media (max-width: $grid-float-breakpoint) {
		&:hover, &:focus {
			background-color: $col-brand-2;
			transition: background-color 0.3s linear;
		}
	}
	@media (min-width: $grid-float-breakpoint) {
		display: block;
		border: none;
	}
}

.nav>li.menu-blog {
	@media (min-width: $screen-sm-min) {
		border: 1px solid $col-brand-2;
	}
}

.navbar-default .navbar-nav>li>a {
	color: #fff;
	text-transform: uppercase;
	font-weight: 300;
}
.navbar-default .navbar-nav>li:focus a,
.navbar-default .navbar-nav>li:hover a {
	color: #fff;
}
.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
	color: #fff;
	transition: border-bottom 0.3s linear;
	border-bottom: solid 1px $col-brand-2;
}

.navbar-default .navbar-nav>.active>a {
	color: $col-brand-2;
	background-color: transparent;
}

.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover
{
	color: $col-brand-2;
	background-color: transparent;
	transition: all 0.3s linear;
}

#carrusel-cabecera {
	margin-top: 60px;
	@media (min-width: $screen-sm-min) {
		margin-top: 0;
	}
	.item {
		height: 320px;
		@media (min-width: $screen-xs-min) {
			height: 410px;
			padding-top: 0;
		}
		@media (min-width: $screen-sm-min) {
			height: 500px;
			padding-top: 0;
		}
		@media (min-width: $screen-lg-min) {
			height: 700px;
		}
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.carousel-caption {
		left: 5%;
		right: 5%;
		bottom: 40px;
	}
	h3 {
		font-size: 1em;
		font-weight: 200;
		color: $col-brand-2;
		margin-top: 0;
		margin-bottom: 0.8em;
		@media (min-width: $screen-sm-min) {
			margin-top: 0.8em;
			font-size: 1.8em;
		}
		@media (min-width: $screen-md-min) {
			margin-top: 1.2em;
			margin-bottom: 1.2em;
			font-size: 2em;
		}
	}
	h4 {
		font-size: 1em;
		p {line-height: 1.4;}
		@media (min-width: $screen-xs-min) {
			font-size: 1.5em;
		}
		@media (min-width: $screen-sm-min) {
			font-size: 1.3em;
			p {line-height: 1.6;}
		}
		@media (min-width: $screen-md-min) {
			font-size: 1.5em;
		}
		@media (min-width: $screen-lg-min) {
			font-size: 2em;
		}
		text-transform: uppercase;
		font-weight: 800;
	}
	.carousel-caption .btn {
		padding: 1em 3em;
		font-family: $font-1;
	}
	.btn-trans.enlace {
		font-size: 0.7em;
		@media (min-width: $screen-xs-min) {
			font-size: 1em;
		}
		&:hover, &:focus, &:active {
			a {color: $col-brand-1;}
			background-color: #fff;
		}
	}
}

.carousel-indicators {
	bottom: 4px;
	@media (min-width: $screen-sm-min) {
		bottom: 10px;
	}
	li {
		background-color: #fff;
		margin-right: 0.8em;
		&.active {
			background-color: $col-brand-2;
			border: none;
			margin-right: 0.8em;
		}
	}
}

.img-cabecera {
	display: block;
	background-repeat: no-repeat;
	background-position: 50% 50%; 
	background-size: cover;
	height: 240px;
	width: 100%;
	// object-fit: cover;
	@media (min-width: $screen-sm-min) {
		height: 335px;
	}
}