.blog .wrap.container,
.single-post .wrap.container{
	max-width: 800px;
	margin: 0 auto;
}

.single-post iframe {
	max-width: 100% !important;
}

article header {
	margin-top: 5em;
	margin-bottom: 2em;
	.entry-title, 
	.entry-title a {
		color: $col-brand-2;
		padding: 0.3em 0.6em;
		margin-left: -0.6em;
	}
	.entry-title a:hover,
	.entry-title a:focus {
		color: $col-brand-1;
		transition: color 0.3s linear;
	}
	time,
	.byline,
	{
		color: lighten($col-brand-1, 40%);
		font-family: $font-2;
	}
}

.separador {
	width: 8em;
	border-top: solid 0.5em darken($col-fondo-1, 10%);
}

.entry-summary,
.entry-content {
	margin-bottom: 1em;
	font-family: $font-1;
}

.entry-info {
	margin-bottom: 5em;
}

.entry-cats {
	float: left;
	font-size: 0.8em;
	a {
		background-color: $col-brand-2;
		padding: 0.6em 1.2em;
		margin-right: 0.4em;
		&:hover,
		&:focus {
			background-color: $col-brand-1;
			color: $col-brand-2;
		}
	}
}

.entry-sigue {
	float: right;
	font-size: 0.8em;
	letter-spacing: 1px;
	font-weight: 400;
	font-family: $font-1;
	text-transform: uppercase;
	padding: 0.6em 1.2em;
	&:hover,
	&:focus {
		background-color: $col-brand-1;
		color: $col-brand-2;
	}
}

.posts-navigation {
	margin-bottom: 4em;
	.nav-previous a,
	.nav-next a {
		padding: 0.6em 1.2em;
		&:hover,
		&:focus {
			background-color: $col-brand-1;
			color: $col-brand-2;
		}
	}
}

.comment-body {
	padding: 1em 0;
}

footer.comment-meta {
	color: $col-brand-1;
	background-color: transparent;
	font-style: italic;
	padding-bottom: 1.5em;
}