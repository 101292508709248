.page-header {
	padding-top: 1em;
	padding-bottom: 1em;
  @media (min-width: $screen-sm-min) {
		padding-top: 2.4em;
		padding-bottom: 2.4em;
	}
	@media (min-width: $screen-md-min) {
		padding-top: 4em;
		padding-bottom: 4em;
	}
	text-align: center;
	border: none;
	margin: 0;
	text-transform: uppercase;
}

.page-template-template-libros,
.page-template-template-bio,
.page-template-template-cazador {
	.wrap {
		width: 100%;
		padding: 0 15px;
	}
}

.page-template-template-consultoria,
.page-template-template-conferencias,
body.contacto {
	.content-page{
	  @media (min-width: $screen-sm-min) {
			margin: 0 -15px;
			padding: 0 30px;
		}
	}
}

.page-template-template-libros .excerpt-page {
	margin: 0 -15px;
}

.img-banner {
	display: block;
	border: none;
	outline: 1px solid white;
	background-repeat: no-repeat;
	background-position: 50% 50%; 
	background-size: cover;
	width: 100%;
	height: 340px;
	@media (min-width: $screen-sm-min) {
		height: 400px;
	}
	@media (min-width: $screen-md-min) {
		height: 500px;
	}
	@media (min-width: $screen-lg-min) {
		height: 600px;
	}
	.page-template-template-libros & {
		height: 220px;
		@media (min-width: $screen-sm-min) {
			height: 360px;
		}
		@media (min-width: $screen-md-min) {
			height: 450px;
		}
		@media (min-width: $screen-lg-min) {
			height: 640px;
		}
	}
}

.excerpt-page {
  color: $col-brand-2;
	font-size: 1.8em;
  font-weight: 800;
	padding: 0 15px;
  padding-bottom: 1em;
	p {line-height: 1.2;}
  @media (min-width: $screen-sm-min) {
	  font-size: 2em;
		p {line-height: inherit;}
  }
}

#banner-pagina {
	margin: 0 -15px;
}

.subtitulo-pagina {
	color: $col-brand-2;
	@media (min-width: $screen-sm-min) {
		padding-top: 2em;
	}
}

.libros-excerpt {
	color: $col-brand-2;
	margin: 1em 0;
	padding: 0.3em 0;
	font-family: $font-1;
	font-size: 1.4em;
	font-weight: 700;
	p {line-height: 1.5};
	@media (min-width: $screen-sm-min) {
		font-size: 2em;
	}
}

.libros-lista .container{
	padding: 0; 
}

.item-libro {
	@include make-xs-column (6);
	@include make-sm-column (4);
	padding-bottom: 2em;
}

h2.libro-titulo {
	font-size: 1.2em;
}

.libro-meta {
	color: lighten($col-brand-1, 40%);
}

.container-ppto {
	text-align: center;
	.enlace {
		color: $col-brand-1;
		&:hover, &:focus, &:active {
			a {color: $col-brand-2;}
			background-color: $col-brand-1;
		}
	}
}

/* Paginación */
nav.posts-navigation {
  .nav-links {
    padding-left: 0;
  };
  a{
    font-weight: 400;
    color: $col-brand-1;
    padding: 0.6em 1.2em;
    &:hover, &:active, &:focus {
      color: $col-fondo-1;
      background-color: $col-brand-2;
      transition: all 0.3s linear;
    }
  }
  .nav-previous {
    float: left
  };
  .nav-next {
    float: right
  };
}