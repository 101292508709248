@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Hairline.otf") format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-UltraLight.otf") format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Light.otf") format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.otf") format('opentype');
  font-weight: 400;
  font-style: normal;
}

/* @font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.otf") format('opentype');
  font-weight: 500;
  font-style: normal;
} */

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.otf") format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.otf") format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.otf") format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.otf") format('opentype');
  font-weight: 900;
  font-style: normal;
}