/* Página de El cazador de cerebros (antes, Programas) */
.page-template-template-cazador {
	.main {
		padding-bottom: 0;
	}
	a:hover, a:focus, a:active {
		transition: all 0.3s linear;
	}
	.page-header {
		text-transform: none;
		padding-bottom: 0;
	}
	.img-banner {
		height: 260px;
		@media (min-width: $screen-sm-min) {
			height: 300px;
		}
		@media (min-width: $screen-md-min) {
			height: 350px;
		}
		@media (min-width: $screen-lg-min) {
			height: 400px;
		}	
	}
	h2 {
		margin-top: 0;
	}
	.titulo-seccion {
		margin-top: 2em;
		font-size: 2.2em;
		font-style: normal;
		font-weight: 800;
		text-align: center;
	}
	.excerpt p {
		padding: 2em 0;
		// line-height: 1.5em;
		@media (min-width: $screen-sm-min) {
			font-size: 1.2em;
			// line-height: 1.4;
		}
	}
	.p1 {
		@extend h2;
		color: $col-brand-2;
		padding: 0;
		text-align: center;
	}
	.container.wrap {
		width: 100%;
		padding: 0 15px;
	}
	.video-uno {
		@include make-sm-column (12);
	}
	.video-tres {
		@include make-sm-column (4);
	}
	.row-video-texto {
		// @include make-row ();
		.item-video {
			@include make-xs-column (12);
			@include make-md-column (6);
			padding-bottom: 1em;
		}
		.item-video .video-titulo a {
		// &:nth-child(even) .item-video .video-titulo a {
			color: $col-brand-2;
			&:hover, &:focus, &:active {
				color: $col-brand-1;
			} 
		}
		.video-titulo {
			font-size: 1.4em;
			text-transform: none;
			padding: 0;
			@media (min-width: $screen-xs-min) {
				font-size: 1.8em;
			}
			@media (min-width: $screen-sm-min) {
				font-size: 2em;
			}
		}
	}
}

.intro-ecuador {
	text-align: center;
	padding-top: 2.5em;
}

section.programas-lista {
	margin: 2em -15px 0 -15px;
	padding: 3em 0;
	background-color: #f1f1f1;
	.container {
		// padding: 3em;
		// background-color: #f1f1f1;
	}
	.btn.enlace {
		background-color: #f1f1f1;
		&:hover {
			a {color: #f1f1f1;}
			background-color: $col-brand-1;
			transition: all 0.3s linear;
		}
	}
}

.entry-content {
	padding: 1em 0;
}
.entry-temas {
	display: block;
	font-size: 0.8em;
	a {
		background-color: $col-brand-2;
		padding: 0.6em 1.2em;
		margin-right: 0.4em;
		&:hover,
		&:focus {
			background-color: $col-brand-1;
			color: $col-brand-2;
		}
	}
}