// Fonts (Google, Icon fonts)
@import url("//fonts.googleapis.com/css?family=Dosis:300,400,500,700,800");
// @import url("//fonts.googleapis.com/css?family=Arvo:400italic");
@import url("//fonts.googleapis.com/css?family=Arvo:400,400i");


$font-1: 'Montserrat', sans-serif;
$font-2: 'Arvo', serif;

/* Reduzco el ancho por defecto de todas las páginas de la web al limitar
el tamaño de los .container al que tienen en pantalla intermedia*/
.container {
  @media (min-width: $screen-lg-min) {
    width: $container-md;
  }
}
@media (min-width: $screen-lg-min) {
  @include make-grid(md);
}

body {
	font-family: $font-1;
	font-weight: 300;
	font-size: 16px;
	color: $col-brand-1;
	background-color: $col-fondo-2;
}

// body.wp-admin.wp-core-ui {
	// background-color: #fff;
// }
// #content_ifr {
// 	background-color: green;
// 	color: red;
// }

/* body .mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {
	background-color: #fff;
} */

.fondo {
	background-color: #fff;
}

.container.wrap {
	background-color: #fff;
}

main {
	background-color: #fff;
}

p {
	font-size: 1em;
	line-height: 1.5;
	@media (min-width: $screen-sm-min) {
		font-size: 1.1em;
		// line-height: 1.8;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-1;
	p {line-height: 1.2};
}

h1 {
	font-weight: 800;
}

a {
	color: $col-brand-1;
	font-weight: bold;
	&:hover, &:active, &:focus {
		color: $col-brand-2;
		transition: color 0.3s linear;
	}
}

section {
	padding-top: 40px;
	padding-bottom: 40px; 
}

/* Limito el el ancho máximo de las imágenes en las entradas
al del contenedor del texto de la propia entrada*/
.post img,
.post figure {
	max-width: 100% !important;
} 

.enlace {
	display: inline-block;
	padding: 0.8em 2.4em;
	border: 1px solid #000;
	margin: 0 auto;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: bold;
	&:hover, &:focus, &:active {
		text-decoration: none;
		transition: background-color 0.3s linear;
	}
}

a:hover {
	text-decoration: none;
	transition: color 0.3s linear;
	transition: background-color 0.3s linear;
}

.centrado {
	text-align: center;
}

.main {
	padding-top: 2.5%;
	padding-bottom: 5%; 
}

.img-responsive {
	width: 100%;
	height: auto;
}

.container.ancho-total {
	width: 100%;
}

.error404 .container.wrap {
	width: 100%;
	text-align: center;
	.alert {
		display: inline-block;
		font-size: 1.2em;
		font-weight: 200;
	}
	.alert-warning {
		background-color: $col-fondo-3;
		border-color: $col-fondo-3;
		color: col-brand-1;
	}
}



/*Botones para compartir en redes sociales
creados con plugin Simple Share Buttons Adder */

.ssba.ssba-wrap {
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	border: 1px solid $col-brand-1;
	margin: 24px 0;
	padding-left: 14px;
	padding-right: 14px;
	a {
		padding: 8px;
		padding-bottom: 12px;
		&:first-child {
			margin-left: 8px;
		}
		&:hover, &:active, &:focus  {
			border-color: col-brand-2;
			background-color: $col-brand-2;
			transition: all 0.3s linear;
		}
		&.ssba_facebook_share {}
		&.ssba_twitter_share {}
		&.ssba_email_share {}
	}
}

/* Para tener cinco columnas en la página de Conferencias
http://www.wearesicc.com/quick-tips-5-column-layout-with-twitter-bootstrap/
*/

// .col-xs-15,
// .col-sm-15,
// .col-md-15,
// .col-lg-15,
// .col-xs-17,
// .col-sm-17,
// .col-md-17,
// .col-lg-17, {
//   position: relative;
//   min-height: 1px;
//   padding-right: 10px;
//   padding-left: 10px;
// }
// .col-xs-15 {
//   width: 20%;
//   float: left;
// }
// .col-xs-17 {
// 	width: 14.2857143%;
//   float: left;
// }
// @media (min-width: $screen-sm-min) {
// 	.col-sm-15 {
// 		width: 20%;
// 		float: left;
// 	}
// 	.col-sm-17 {
// 		width: 14.2857143%;
// 	  float: left;
// 	}
// }
// @media (min-width: $screen-md-min) {
// 	.col-md-15 {
// 		width: 20%;
// 		float: left;
// 	}
// 	.col-md-17 {
// 		width: 14.2857143%;
// 	  float: left;
// 	}
// }
// @media (min-width: $screen-lg-min) {
// 	.col-lg-15 {
// 		width: 20%;
// 		float: left;
// 	}
// 	.col-lg-17 {
// 		width: 14.2857143%;
// 		float: left;
// 	}
// }