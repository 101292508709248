.wpcf7 {
  label {
    color: $col-brand-2;
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .row {
    margin-bottom: 3em;
  }
  input {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1p $col-brand-1;
  }
  .col-6 {
    @include make-xs-column (12);
    @include make-sm-column (6);
  }
  .name.col-6 {
    margin-bottom: 3em;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }
  .col-12 {
    @include make-xs-column (12);
  }
  textarea.wpcf7-form-control.wpcf7-textarea.col-12 {
    border-color: $col-brand-1;
  }
  .row-boton {
    text-align: center;
  }
  .boton-contacto {
    display: inline-block;
  }
  input.wpcf7-submit {
    @include button-variant($col-brand-1, $col-brand-2, $col-brand-2);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0.6em 1.2em;
  }
}