.tax-tema .page-header {
  padding-top: 1em;
  @media (min-width: $screen-sm-min) {
    padding-top: 1.6em;
  }
  @media (min-width: $screen-md-min) {
    padding-top: 2em;
  }
}

.tema-header {
  padding-top: 2em;
  text-align: center;
  line-height: 1.4;
  font-weight: 200;
  font-size: 1em;
  @media (min-width: $screen-sm-min) {
    font-size: 1.2em;
  }
  @media (min-width: $screen-md-min) {
    font-size: 1.4em;
    line-height: 1.6;
  }
}
.enlace-video-tema,
.reflexion-tema {
  position: relative;
  border: solid 4px $col-medios;
  padding: 18px;
  padding-bottom: 54px;
  h2,
  h2 a {
    color: $col-medios;
  }
  a.item-enlace {
    bottom: 12px;
  }
  a.item-enlace:hover, a.item-enlace:focus, a.item-enlace:active {
    color: #fff;
    background-color: $col-medios;
    transition: all 0.3s linear;
  }
}
.enlace-video-tema {
  padding-bottom: 54px;
  .img-video-tema {
    position: relative;
    display: block;
    &:hover {
      opacity: 0.7;
      color: #000;
    }
  }
}
.reflexion-tema {
  margin-bottom: 3em;
  h2 a {
    margin-left: -0.4em;
    padding: 0.2em 0.4em;
    line-height: 1.4;
  }
  h2 a:hover,
  h2 a:focus,
  h2 a:active {
    color: #fff;
    background-color: $col-medios;
    transition: all 0.3s linear;
  }
}

.item-tipo, 
.item-enlace {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
}

a.item-tipo {
  font-weight: normal; 
}

.item-enlace {
  position: absolute;
  padding: 0.4em 0.8em;
  margin-left: -0.8em;
}

.reflexion-tema {
  position: relative;
  margin-top: 20px;
}

.reflexion-logo{
  // background-image: url('../images/cuaderno_rojo.svg');
  background-image: url('../images/notebook_48.svg');
  // width: 18px;
  // height: 30px;
  width: 32px;
  height: 41px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-size: cover;
  @media (min-width: $screen-sm-min) {
    top: 12px;
  }
}

.tv-logo,
.la2-logo{
  position: absolute;
  bottom: 12px;
  right: 12px;
  background-size: cover;
  @media (min-width: $screen-sm-min) {
    top: 12px;
  }
}
.tv-logo {
  background-image: url('../images/logo_tv.svg');
  width: 37px;
  height: 27px;
}
.la2-logo {
  background-image: url('../images/logo_tve_2.svg');
  width: 50px;
  height: 44px;
}

.lista-tema {
  padding-top: 2em;
  padding-bottom: 2em;
}

.tema-item {
  border: solid 1px;
  padding: 12px;
  font-family: $font-1;
  @include make-row();
  margin: 0 6px 24px 6px;
  @media (min-width: $screen-sm-min) {
    margin: 12px 0;
  }
  /* Para crear contexto para floats y logo (absolute) */
  position: relative;

  .item-imagen {
    @include make-xs-column (12);
    @include make-sm-column (4);
    margin-bottom: 18px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }

  .item-info {
    @include make-xs-column (12);
    @include make-sm-column (8);
  }

  .item-titulo {
    text-transform: none;
    padding-bottom: 1em;
    &:hover a, &:active a, &:focus a {
      color: $col-brand-1;
      transition: color 0.3s linear;
    }
  }

  .item-logo {
    position: absolute;
    bottom: 12px;
    right: 12px;
    background-size: cover;
    @media (min-width: $screen-sm-min) {
      top: 12px;
    }
  }
  
  .item-enlace {
    bottom: 0;
  }

  &.tema-post {
    border-color: $col-post;
    .item-titulo a {
      color: $col-post;
    }
    a.item-enlace:hover, a.item-enlace:focus, a.item-enlace:active {
      color: #fff;
      background-color: $col-post;
      transition: all 0.3s linear;
    }
  }

  &.tema-video {
    border-color: $col-video;
    .item-titulo a {
      color: $col-video;
    }
    a.item-enlace:hover, a.item-enlace:focus, a.item-enlace:active {
      color: #fff;
      background-color: $col-video;
      transition: all 0.3s linear;
    }
  }

  &.tema-audio {
    border-color: $col-audio;
    .item-titulo a {
      color: $col-audio;
    }
    a.item-enlace:hover, a.item-enlace:focus, a.item-enlace:active {
      color: #fff;
      background-color: $col-audio;
      transition: all 0.3s linear;
    }
  }

  .post-logo{
    background-image: url('../images/texto.svg');
    width: 28px;
    height: 39px;
  }

  .video-logo{
    background-image: url('../images/video.svg');
    width: 39px;
    height: 26px;
  }

  .audio-logo{
    background-image: url('../images/audio.svg');
    width: 39px;
    height: 30px;
  }
}