footer {
	background-color: $col-fondo-2;
	color: #fff;
	.logo-icono {
		@media (min-width: $breakpoint-menu) {
			width: 26px;
			height: 57px;
		}
	}
	.logo-nombre {
		font-family: $font-1;
		@media (min-width: $breakpoint-menu) {
			font-size: 1.2em;
		}
	}
	.nombre-1,
	.nombre-2 {
		font-size: 1.4em;
		font-weight: 600;
		line-height: 1em;
		margin: 0;
	}
	.nombre-1 {
		display: block;
	}
	.nombre-2 {
		display: inline-block;
	}
	.logo-texto {
		font-family: $font-2;
	}
	section {
		padding-top: 0; 
	}
}

.footer-col {
	@include make-sm-column(6);
	text-align: center;
	padding-top: 2em;
	padding-bottom: 2em;
}

.logo-footer {
	text-align: left;
	margin: 0 auto;
	width: 60%;
}

#widget-footer-rss {
	.info-contacto {
		margin-bottom: 2em;
	}
	.info-contacto a {
		color: $col-brand-2;
		&:hover,
		&:focus,
		&:active {
			color: $col-fondo-1;
			transition: color 0.3s linear;
		}
	}

	.info-redes a {
		font-family: Flaticon;
		font-size: 1.4em;
		color: $col-fondo-1;
		margin-right: 0.5em;
		text-decoration: none;
		&:hover:after {
			color: $col-brand-2;
			transition: color 0.3s linear;
		}
	}

	.info-redes a.icono-twitter:after {
		content: $font-Flaticon-twitter-logo-silhouette;
	}

	.info-redes a.icono-facebook:after {
		content: $font-Flaticon-facebook-logo;
	}
	.info-redes a.icono-youtube:after {
		content: $font-Flaticon-youtube;
	}
	.info-redes a.icono-linkedin:after {
		content: $font-Flaticon-linkedin-logo;
	}
	.info-redes a.icono-rss:after {
		content: $font-Flaticon-rss-symbol;
	}
}