/**
/* Para que pueda haber secciones que ocupen todo el ancho de la página en la página de inicio (home),
/* pero no en el resto de páginas.
*/
.home.page .wrap.container {
	width: 100%;
}
.home.page .main {
	padding-left: 0; 
	padding-right: 0;
	padding-bottom: 0;
}

.titulo-seccion {
	font-style: italic;
	font-size: 1.4em;
	padding-bottom: 20px;
	@media (min-width: $screen-sm-min) {
		font-size: 2em;
		padding-bottom: 40px;
	}
}

#articulos {
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 32px;
	hr.estrella {
		max-width: 200px;
		border-top: solid 2px;
		text-align: center;
		margin: 30px auto 60px;
	}
	hr.estrella:after {
		font-family: Flaticon;
		font-size: 2em;
		color: #000;
		background-color: #fff;
		display: inline-block;
		position: relative;
		top: -.6em;
		padding: 0 .4em;
	}
	& hr.estrella:after {
		content: $font-Flaticon-tool-1; //pluma
	}
	.titulo-seccion {

		font-family: $font-2;	
	}
	.item-articulo-inicio {
		@include make-sm-column (4);
		margin-bottom: 60px;
		@media (min-width: $screen-sm-min) {
			margin-bottom: 0;
		}
		.articulo-titulo {
			padding: 0.5em 0 0.5em 0;
			@media (min-width: $screen-sm-min) {
				padding: 1.5em 0 1.5em 0;
			}
			// font-size: 1.2em;
			text-transform: uppercase;
		}
		.articulo-fecha {
			font-style: italic;
			color: #7d7d7d;
			font-weight: 300;
		}
	} 
}

#bio {
	@include make-row();
	background-color: $col-fondo-1;
}
.bio-txt {
	@include make-sm-column(8);
	font-size: 1.2em;
	padding-left: 0;
	a {
		font-weight: bold;
		float: right;
	}
}
.bio-img {
	@include make-sm-column(4);
}

#videos {
	.container.ancho-total {
		padding: 0;
	} 
	.row.cabecera-seccion {
		margin: 0;
		display: block;
		background-repeat: no-repeat;
		background-position: 50% 50%; 
		background-size: cover;
		height: 360px;
		width: 100%;
		background-image: url('../images/cazador_home.jpg');
		padding: 40px 0;
		color: $col-medios;
	}
	.icono-seccion {
		max-width: 220px;
		margin: 0 auto;
		.borde {
			display: inline-block;
			width: 30%;
			border-top: solid 2px $col-medios;
			position: relative;
			top: -1.5em;
		}
	}
	.icono-seccion .icono {
		display: inline-block;
		// background-image: url('../images/cuaderno_rojo.svg');
		background-image: url('../images/notebook_48.svg');
		background-repeat: no-repeat;
		background-position: 50% 50%; 
		// width: 66px;
		// height: 61px;
		width: 48px;
		height: 62px;
		padding: 0 5px;
		margin: 0 auto;
	}
	.titulo-seccion {
		font-family: $font-2;
		text-transform: uppercase;
	}
	.descripcion-seccion {
		font-family: $font-2;
		font-weight: 500;
		line-height: 1.5;
		font-size: 1.1em;
		@media (min-width: $screen-sm-min) {
			font-size: 1.3em;
		}
	}
	.videos-contenido {
		font-size: 1em;
		font-family: $font-2;
		padding: 45px 15px;
	}
}

.video-uno {
	@include make-xs-column (12);	
}

.video-dos {
	@include make-sm-column (6);
}

.item-video {
	padding-bottom: 3em;
}

.video-tema {
	display: inline-block;
	background-color: $col-brand-2;
	color: #000;
	padding: 0.8em;
	font-size: 0.7em;
	font-style: italic;
	font-weight: bold;
}
.img-video {
	position: relative;
	display: block;
	.la2-logo {
		position: absolute;
		top: 12px;
		right: 12px;
	}
	&:hover {
		opacity: 0.8;
		transition: opacity linear 0.2s;
	}
	transition: opacity linear 0.2s;
}

.video-titulo {
	font-weight: bold;
	text-transform: uppercase;
	padding: 1.2em 0;
}

.video-descripcion {
	a {
		font-weight: 500;
		padding-left: 0.5em;
	}
}

#temas {
	padding-top: 0;
	padding-bottom: 5px;
	.cabecera-seccion {
		background-color: $col-brand-2;
		background-image: url('../images/constelacion_amarillo.png');
		background-size: 100% auto;
		padding: 40px 0;
		h2 {
			font-weight: 800;
			font-style: normal;
			text-transform: uppercase;
		}
	}
	background-color: darken($col-fondo-1, 10%);
}

.fila-temas {
	@include make-row();
	overflow: hidden;
	margin-bottom: 0.2em;
}

.home .tema {
	@include make-xs-column (6);
	@include make-sm-column (4);
	@include make-md-column (2);
	margin-bottom: 0.2em;
	margin-top: 0.3em;
	padding: 0 0.3em;
}

.page-template-template-temas .tema {
	@include make-xs-column (6);
 	margin-bottom: 0.5em;
 	padding: 0 0.3em;
}  

.tema {
	// @include make-xs-column (6);
	// @include make-sm-column (4);
	// @include make-md-column (2);
 	//  margin-bottom: 0.2em;
 	//  margin-top: 0.3em;
 	//  padding: 0 0.3em;
 	text-align: center;

  .tema-titulo {
  	font-style: italic;
  	display: inline-block;
  	background-color: $col-brand-2;
  	padding: 0 0.5em 0.5em 0.5em;
  	margin-bottom: 1em;
  	border: 1px solid $col-brand-2;
  	h3 {
  		font-family: $font-2;
  		font-size: 1em;
  	}
  }
  .tema-content {
		background-color: $col-fondo-1;
		transition: background-color 0.3s linear;
  	padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		@media (min-width: $screen-sm-min) {
			padding-left: 7%;
			padding-right: 7%;
		}
	  height: 100%;
	  white-space: normal;
	  overflow-wrap: pre-wrap;
  }
  .tema-descripcion {
  	font-weight: bold;
  	font-family: $font-1;
  	padding-bottom: 1em;
  }
  &:hover,
  &:focus,
  &:active {
  	text-decoration: none;
  	.tema-titulo {
  		background-color: $col-fondo-2;
  		border: 1px solid #fff;
  		color: #ffcc33;
  	}
  	.tema-content {
  		background-color: $col-fondo-2;
  	}
  	.tema-descripcion {
  		color: #fff;
  	}
  }
  /* Cuando el tema está inactivo */
  &.tema-inactivo {
		.tema-content {
			background-color: $col-fondo-3;
		}
		.tema-titulo {
  		padding: 0 3em 0.5em 3em;
			color: darken($col-fondo-3, 30%);
			background-color: darken($col-fondo-3, 10%);
			border-color: darken($col-fondo-3, 30%);
		}
		.tema-descripcion {
			color: darken($col-fondo-3, 30%);
		}
	}
}