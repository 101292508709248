.col-libro {
	@include make-sm-column(6);
}
.single-libro {
	h1.entry-title {
		margin-top: 0;
		padding-top: 0;
	}
	article {
		@include make-sm-column(6);
	}
}
.empezar-leer {
	font-family: $font-1;
 	text-transform: uppercase;
 	@extend .btn;
	@extend .btn-default;
	@extend .btn-block;
	border: #000 solid 2px;

	transition: color 0.3s linear;
	transition: background-color 0.3s linear;

	&:hover,
	&:focus {
		color: $col-brand-2 !important;
		background-color: $col-brand-1 !important;
	}
}

.botones-libro {
	margin-top: 2em;
	text-align: center;
	margin-left: 0;
	margin-right: 0;
	padding: 0 2.5em;
}

.rhmgbc-button {
	.arrow {
		display: none;
	}
	margin: 2em auto;
	width: 100% !important;
	font-family: $font-1;
 	text-transform: uppercase;
	color: $col-brand-1 !important;
	background-color: $col-brand-2 !important;
	border: 0 !important;
	border-radius: 0 !important;

	transition: color 0.3s linear;
	transition: background-color 0.3s linear;

	&:hover,
	&:focus {
		color: $col-brand-2 !important;
		background-color: $col-brand-1 !important;
	}
	&.open .rhmgbc-dropdown {
	}
	.rhmgbc-dropdown {
		width: 100% !important;
		margin-top: 2px;
		left: 0;
		background-color: $col-brand-2 !important;
		border-color: $col-brand-2 !important;
		.inner { }
		.eligir { }
		li {
			background-image: none !important;
			background-color: $col-brand-1 !important;
			border-radius: 0 !important;
			padding-top: 0.5em !important;
			padding-bottom: 0.5em !important;
			&:hover,
			&:focus {
				background-color: lighten($col-brand-1, 20%) !important;
				transition: background-color 0.3s linear;
			}
			a::after {
				display: none;
			}
			a:hover,
			a:focus {
				color: $col-brand-2 !important;
				transition: color 0.3s linear;
			}
		}
	}
}

#widget-tiendas-audio {
	font-weight: bold;
	text-transform: uppercase;
	.btn-group {
		width: 100%;
		&.open button {
			color: $col-brand-2;
			background-color: $col-brand-1;
			border-color: $col-brand-1;
			transition: all 0.3s linear;
		}
		button {
			width: 100%;
			font-weight: bold;
			text-transform: uppercase;
			border-color: $col-brand-2;
			background-color: $col-brand-2;
			&:hover {
				color: $col-brand-2;
				background-color: $col-brand-1;
				transition: all 0.3s linear;
			}
			.caret {
				float: right;
				margin-top: 0.5em;
			}
		}
		ul.dropdown-menu {
			width: 100%;
			padding: 0.5em;
			background-color: $col-brand-2;
			div {
				text-align: center;
				text-transform: uppercase;
				padding: 0.3em 0;
			}
			li {
				margin: 0.3em 0;
				background-color: $col-brand-1;
				a {
					padding-top: 0.8em;
					padding-bottom: 0.8em;
					color: #fff;
					font-size: 0.9em;
					font-weight: bold;
					text-align: center;
					text-transform: uppercase;
				}
				a:hover,
				a:focus {
					color: $col-brand-2;
					background-color: lighten($col-brand-1, 20%);
					transition: background-color 0.3s linear;
				}
			}
		}
	}
}