.btn {
	border-radius: 0;
}

.btn-trans {
	background-color: trasparent;
	border-color: #fff; 
}

.btn-trans a {
	color: #fff;
}

.btn-primary {
	color: $col-brand-1;
	background-color: $col-brand-2;
	border-color: $col-brand-2;
	&:hover, &:focus, &active {
		color: $col-brand-2;
		background-color: $col-brand-1;
		transition: all 0.3s linear;
	}
}

.row-enlace {
	@media (min-width: $screen-sm-min) {
		padding-top: 1.5em;
	}
	margin: 2em 0;
}

.btn.enlace {
	&:hover {
		a {color: #fff;}
		background-color: $col-brand-1;
		transition: all 0.3s linear;
	}
}