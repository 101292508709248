.page-template-template-conferencias {
  .titulo-seccion {
    font-style: normal;
    color: $col-brand-2;
  }
  p.p1 {
    line-height: 1.8;
    font-size: 1.1em;
    // &.p1 {
    	margin: 0 -15px
    // };
  }
  .topic-fila {
    @include make-row();
  }

  .item-topic {
    @include make-xs-column (6);
    //@include make-sm-column (3);
    @include make-md-column (3);
  	margin-bottom: 3em;
		@media (min-width: $screen-sm-min) {
  		margin-bottom: 0;
		}
    // @extend .col-xs-15;
    // @extend .col-sm-15;
    // @extend .col-md-15;
    // @extend .col-lg-15;
    img {
    	margin-bottom: 3em;
    }
  }

  .topic-titulo {
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
  }
  .topic-descripcion {
  	font-size: 0.7em;
  	p {line-height: 1.6;}
  	@media (min-width: $screen-xs-min) {
  		font-size: 0.8em;
  		// p {line-height: 1.4;}
  	}
  	@media (min-width: $screen-sm-min) {
  		font-size: 0.9em;
  		// p {line-height: 1.6;}
  	}
  	b {
    	font-size: 1.2em;
    	text-transform: uppercase;
  	}
  }


  .conf-fila {
    @include make-row();
  }
  .item-conf {
    @include make-xs-column (12);
    @include make-sm-column (6);
    padding-bottom: 2em;
  }

  // Para igualar la altura de las dos columnas
  /* .conf-fila {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .item-conf {
    display: flex;
    flex-direction: column;
  } */

  .conf-video {
    position: relative;
    padding-top: 30px;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    & iframe,
    & object,
    & embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .conf-titulo {
    padding-top: 1.2em;
    padding-bottom: 0.4em;
    font-weight: bold;
    text-transform: uppercase;
  }
  .conf-descripcion {}
  .conf-fecha {}
}