// Glyphicons font path
$icon-font-path:			"../fonts/";

// Grid settings
$main-sm-columns:			12;
$sidebar-sm-columns:	4;

// Colores
$col-brand-1:		#373737;
$col-brand-2:		#ffcc00;

$col-fondo-1: 	#f2f2f2;
$col-fondo-2: 	#2b2b2b;
$col-fondo-3: 	#edece8;

$col-video: 		#6699ff;
$col-post:	 		$col-brand-2;
$col-audio: 		#66cc99;
$col-medios: 		#ff3333;
$col-cazador:		#CC0033;

$navbar-default-bg: $col-fondo-2;

$navbar-default-toggle-border-color:	$col-brand-2;
$navbar-default-toggle-hover-bg: 			$col-brand-1;
$navbar-default-toggle-icon-bar-bg: 	$col-brand-2;

// $grid-float-breakpoint:;
$grid-float-breakpoint: 992px; /* Es = $screen-md-min, pero por algún motivo no identifica la variable */
$breakpoint-menu: 1200px; /* Es = $screen-lg-min, pero por algún motivo no identifica la variable */