.page-template-template-consultoria {
  .titulo-seccion {
    font-style: normal;
    color: $col-brand-2;
  }
  // .wrap.container {
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  section {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.linea-fila {
  @include make-row();
}

.item-linea {
  @include make-xs-column (12);
  @include make-sm-column (4);
  text-align: center;
  padding: 2em;
}
.linea-descripcion {
  text-transform: uppercase;
  background-color: lighten($col-brand-1, 70%);
  border-radius: 50%;
  margin: 4em;
  padding: 3em 0;
  font-size: 0.8em;
  @media (min-width: $screen-xs-min) {
    margin: 0;
  }
  @media (min-width: $screen-sm-min) {
    padding: 4em 0;
  }
  @media (min-width: $screen-md-min) {
    padding: 5em 0;
    font-size: 1em;
  }
  @media (min-width: $screen-lg-min) {
    padding: 6em 0;
  }
}
.linea-titulo {
  font-size: 1.2em;
  font-weight: bold;
  text-transform: uppercase;
}

.clientes-fila {
  @include make-row();
  margin-bottom: 3em;
}


.item-cliente {
  @include make-xs-column (6);
  @include make-sm-column (3);
  margin-bottom: 4em;
  @media (min-width: $screen-xs-min) {
    padding-left: 4em;
    padding-right: 4em;
    margin-bottom: 4em;
  }
  @media (min-width: $screen-sm-min) {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;
  }
}