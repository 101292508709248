.page-template-template-medios {
  h2 {
    color: $col-brand-2;
  }
  .medios-video-dest {
    @include make-xs-column (12);
    @include make-sm-column (6);
  }
  .video-dest {
    /* Para crear contexto para la posición del iframe */
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /* Paginación: personalización de colores */
  nav.posts-navigation {
    a{
      color: $col-medios;
      &:hover, &:active, &:focus {
        color: $col-fondo-1;
        background-color: $col-medios;
      } 
    }
  }
}

.medio-item {
  border: solid 1px $col-fondo-3;
  padding: 12px;
  font-family: $font-1;
  @include make-row();
  margin: 0 6px 24px 6px;
  @media (min-width: $screen-sm-min) {
    margin: 12px 0;
  }
  /* Para crear contexto para floats y logo (absolute) */
  position: relative;

  .item-imagen {
    @include make-xs-column (3);
    @include make-sm-column (1);
    padding: 0;
    margin-bottom: 18px;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }
  .item-info {
    @include make-xs-column (9);
    @include make-sm-column (11);
    padding-left: 30px;
  }
  .item-tipo, 
  .item-enlace {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
  }
  .item-medio {
    color: $col-medios;
    font-weight: 600;
  }
  .item-titulo {
    font-size: 1.2em;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 2em;
    a {color: lighten($col-brand-1, 20%);}
    &:hover a, &:active a, &:focus a {
      color: $col-medios;
      transition: color 0.3s linear;
    }
  }
  a.item-enlace {
    position: absolute;
    bottom: 0px;
    padding: 0.4em 0.8em;
    margin-left: -0.8em;
    &:hover, &:focus, &:active {
      color: #fff;
      background-color: $col-medios;
      transition: all 0.3s linear;
    }
  }
  .item-logo {
    position: absolute;
    bottom: 12px;
    right: 12px;
    background-size: cover;
    @media (min-width: $screen-sm-min) {
      top: 12px;
    }
  }
}

.medio-texto {
  background-color: $col-fondo-3;
  border-color: $col-fondo-3;
}

.medio-video {
  .item-imagen {
    @include make-xs-column (12);
    @include make-sm-column (4);
    padding: 0;
  }
  .item-info {
    @include make-xs-column (12);
    @include make-sm-column (8);
  }
}

.texto-logo{
  background-image: url('../images/laptop_medios.svg');
  width: 39px;
  height: 25px;
}

.video-logo{
  background-image: url('../images/video_medios.svg');
  width: 39px;
  height: 26px;
  color: $col-medios;
}

.audio-logo{
  background-image: url('../images/audio_medios.svg');
  width: 39px;
  height: 30px;
}

.pdf-medio {
  color: lighten($col-brand-1, 40%);
  font-size: 0.9em;
  font-weight: 200;
}

/* Para igualar la altura de las dos columnas usando flexbox
(finalmente lo descarto porque da problemas en ciertos navegadores).*/
// .medio-item {
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   flex-wrap: wrap;
// }
// .item-imagen,
// .item-info {
//   display: flex;
//   flex-direction: column;
// }